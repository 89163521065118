#root {
    @extend .h-100;

    .content-wrapper {
        @extend .h-100;
        @extend .d-flex;
        @extend .flex-column;
    }
}

main.container {
    $padding: $grid-gutter-width / 2;
    background-color: $white;
    padding: $padding $padding;

    // Compensate for fixed header and footer
    $navbar-height: $navbar-brand-height+(2*$navbar-brand-padding-y)+(2*$navbar-padding-y);
    margin-top: $navbar-height;
    margin-bottom: $navbar-height;
}
